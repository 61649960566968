import { configureStore } from '@reduxjs/toolkit';
import windowsReducer from './reducers/windows';
import ubuntuReducer from './reducers/ubuntu';
import userReducer from './reducers/user';
import appReducer from './reducers/app';
import retroReducer from './reducers/retro';
import roomReducer from './reducers/room';

export default configureStore({
  reducer: {
    app: appReducer,
    windows: windowsReducer,
    user: userReducer,
    ubuntu: ubuntuReducer,
    retro: retroReducer,
    room: roomReducer,
  },
});

import React from 'react';

import './style.scss';

export default function About() {
  return (
    <div className="app-finder">
      <div className="app-finder__topbar">
        A propos
      </div>
      <div className="app-finder__container">
        lorem ipsum lorem ipsum

      </div>
    </div>
  );
}

import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES, setLanguage } from '../../../../../reducers/app';
import ICON_FR from './icon-fr.png';
import ICON_EN from './icon-en.png';
import ICON_JP from './icon-jp.png';

import './style.scss';

export default function Language() {
  const dispatch = useDispatch();
  const refDropdown = useRef(null);
  const app = useSelector((state) => state.app);

  const [isDropdownActive, setIsDropdownActive] = React.useState(false);

  const handleSwitchDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const handleSelectLanguage = (language) => () => {
    dispatch(setLanguage(language));
    setIsDropdownActive(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsDropdownActive(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  return (
    <div className="topbar-language" ref={refDropdown}>
      <button type="button" className="topbar__button btn-language" onClick={handleSwitchDropdown}>
        {app.language === LANGUAGES.FRENCH && (
        <span>
          <img src={ICON_FR} alt="" />
        </span>
        )}
        {app.language === LANGUAGES.ENGLISH && (
        <span>
          <img src={ICON_EN} alt="" />
        </span>
        )}
      </button>

      {isDropdownActive && (
      <div className="dropdown">
        <button type="button" className="btn-switch" onClick={handleSelectLanguage(LANGUAGES.FRENCH)}>
          <img src={ICON_FR} alt="" />
          <span>Français</span>
        </button>
        <button type="button" className="btn-switch" onClick={handleSelectLanguage(LANGUAGES.ENGLISH)}>
          <img src={ICON_EN} alt="" />
          <span>Anglais</span>
        </button>
        <button type="button" className="btn-switch disabled" onClick={handleSelectLanguage(LANGUAGES.ENGLISH)}>
          <img src={ICON_JP} alt="" />
          <span>Japonais</span>
        </button>
      </div>
      )}
    </div>
  );
}

import React from 'react';

export default function Settings() {
  return (
    <div className="app-finder">
      <div className="app-finder__topbar">
        topbar settings
      </div>
      <div className="app-finder__container">
        Container settings
      </div>
    </div>
  );
}

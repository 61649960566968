import React from 'react';

export default function Avis() {
  return (
    <div>
      <div>topbar</div>
      <div>
        <div>sidebar</div>
        <div>content</div>
      </div>
    </div>
  );
}

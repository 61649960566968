import Phaser from 'phaser';
import MatterEntity from './MatterEntity';

const ENEMIES_PATH = 'assets/images/enemies/';
const ENEMIES_SOUNDS_PATH = 'assets/sounds/';

export default class Enemy extends MatterEntity {
  static preload(scene) {
    scene.load.atlas('enemies', `${ENEMIES_PATH}enemies.png`, `${ENEMIES_PATH}enemies_atlas.json`);
    scene.load.animation('enemies_anim', `${ENEMIES_PATH}enemies_anim.json`);

    scene.load.audio('bear', `${ENEMIES_SOUNDS_PATH}bear.mp3`);
    scene.load.audio('wolf', `${ENEMIES_SOUNDS_PATH}wolf.mp3`);
    scene.load.audio('ent', `${ENEMIES_SOUNDS_PATH}ent.mp3`);
  }

  constructor(data) {
    const { scene, enemy } = data;

    const drops = JSON.parse(enemy.properties.find((p) => p.name === 'drops').value);
    const health = enemy.properties.find((p) => p.name === 'health').value;
    super({
      scene,
      x: enemy.x,
      y: enemy.y,
      texture: 'enemies',
      frame: `${enemy.name}_idle_1`,
      drops,
      health,
      name: enemy.name,
    });

    const { Body, Bodies } = Phaser.Physics.Matter.Matter;
    const enemyCollider = Bodies.circle(this.x, this.y, 12, { isSensor: false, label: 'enemyCollider' });
    const enemySensor = Bodies.circle(this.x, this.y, 80, { isSensor: true, label: 'enemySensor' });
    const compoundBody = Body.create({ parts: [enemyCollider, enemySensor], frictionAir: 0.35 });

    this.setExistingBody(compoundBody);
    this.setFixedRotation();

    // Player detection
    this.scene.matterCollision.addOnCollideStart({
      objectA: [enemySensor],
      callback: (other) => {
        if (other.gameObjectB && other.gameObjectB.name === 'player') {
          this.attacking = other.gameObjectB;
        }
      },
      context: this.scene,
    });
  }

  attack = (target) => {
    if (target.dead || this.dead) {
      clearInterval(this.attackTimer);
      return;
    }
    target.hit();
  };

  update() {
    if (this.dead) return;
    if (this.attacking) {
      const direction = this.attacking.position.subtract(this.position);
      if (direction.length() > 24) {
        direction.normalize();
        this.setVelocityX(direction.x);
        this.setVelocityY(direction.y);

        if (this.attackTimer) {
          clearInterval(this.attackTimer);
          this.attackTimer = null;
        }
      } else if (this.attackTimer == null) {
        this.attackTimer = setInterval(this.attack, 500, this.attacking);
      }
    }

    // Enemy direction
    this.setFlipX(this.velocity.x < 0);

    // Manage animations
    if (Math.abs(this.velocity.x) > 0.1 || Math.abs(this.velocity.y) > 0.1) {
      this.anims.play(`${this.name}_walk`, true);
    } else {
      this.anims.play(`${this.name}_idle`, true);
    }
  }
}

import Phaser from 'phaser';
import Player from '../Player';
import Resource from '../Resource';
import DropItem from '../DropItem';
import Enemy from '../Enemy';

const DEFAULT_MAPS_PATH = 'assets/images/maps/';

export default class MainScene extends Phaser.Scene {
  constructor() {
    super('MainScene');

    this.enemies = [];
  }

  preload() {
    Player.preload(this);
    Enemy.preload(this);
    Resource.preload(this);
    DropItem.preload(this);

    // Import map
    this.load.image('tiles', `${DEFAULT_MAPS_PATH}RPG_Nature_Tileset.png`);
    this.load.tilemapTiledJSON('map', `${DEFAULT_MAPS_PATH}map.json`);
  }

  create() {
    // Create map
    this.map = this.make.tilemap({ key: 'map' });
    const tileset = this.map.addTilesetImage('RPG_Nature_Tileset', 'tiles', 32, 32, 0, 0);

    // Create layers
    const layerWorld = this.map.createLayer('World', tileset, 0, 0);
    layerWorld.setCollisionByProperty({ collides: true });
    this.matter.world.convertTilemapLayer(layerWorld);

    this.map.createLayer('Ground', tileset, 0, 0);

    // Create resources
    this.map.getObjectLayer('Resources')
      .objects.forEach((resource) => new Resource({
        scene: this,
        resource,
      }));

    // Create enemies
    this.map.getObjectLayer('Enemies')
      .objects.forEach((enemy) => this.enemies.push(new Enemy({
        scene: this,
        enemy,
      })));

    // Create player
    this.player = new Player({
      scene: this,
      x: 250,
      y: 250,
      texture: 'female',
      frame: 'townsfolk_f_idle_1',
    });

    // Set player movements
    this.player.inputKeys = this.input.keyboard.addKeys({
      up: Phaser.Input.Keyboard.KeyCodes.Z,
      down: Phaser.Input.Keyboard.KeyCodes.S,
      left: Phaser.Input.Keyboard.KeyCodes.Q,
      right: Phaser.Input.Keyboard.KeyCodes.D,
    });
  }

  update() {
    this.enemies.forEach((enemy) => enemy.update());
    this.player.update();
  }
}

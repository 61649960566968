import React, { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ICON_STAR from './icon-star.png';
import ICON_CLIP from './icon-clip.png';
import ICON_GLASSES from './icon-glasses.png';
import ICON_ARCHIVE from './icon-archive.png';
import ICON_SENT from './icon-sent.png';
import ICON_TRASH from './icon-trash.png';
import ICON_DRAFTS from './icon-drafts.png';
import ICON_INBOX from './icon-inbox.png';
import ICON_GET_MAIL from './icon-get-mail.png';
import ICON_WRITE_MAIL from './icon-write-mail.png';
import ICON_CONNEXION from './icon-connection.jpeg';

import Row from './Row';

import './style.scss';
import { setMailRead } from '../../../../reducers/ubuntu';

export default function Mail() {
  const dispatch = useDispatch();
  const itemsRef = useRef([]);
  const ubuntu = useSelector((state) => state.ubuntu);

  const [reader, setReader] = useState(null);

  const activateMailReader = (mail) => {
    setReader(mail);

    itemsRef.current.forEach((item) => {
      if (item.getID() !== mail.id) {
        item.removeSelected();
      }
    });

    dispatch(setMailRead(mail.id));
  };

  return (
    <div className="app-mail">
      <div className="app-mail__topbar">
        <button type="button" className="btn">
          <img src={ICON_GET_MAIL} alt="" />
          <span>Relever</span>
        </button>
        <button type="button" className="btn">
          <img src={ICON_WRITE_MAIL} alt="" />
          <span>Ecrire</span>
        </button>
      </div>
      <div className="app-mail__container">
        <div className="app-mail__sidebar">
          <div>
            <p className="block-title">Tous les dossiers</p>
          </div>
          <ul className="block-list">
            <li>
              <button type="button" className="btn">
                <img src={ICON_INBOX} alt="" />
                <span>Courrier entrant</span>
              </button>
            </li>

            <li>
              <button type="button" className="btn">
                <img src={ICON_DRAFTS} alt="" />
                <span>Brouillons</span>
              </button>
            </li>

            <li>
              <button type="button" className="btn">
                <img src={ICON_SENT} alt="" />
                <span>Envoyés</span>
              </button>
            </li>

            <li>
              <button type="button" className="btn">
                <img src={ICON_ARCHIVE} alt="" />
                <span>Archives</span>
              </button>
            </li>

            <li>
              <button type="button" className="btn">
                <img src={ICON_TRASH} alt="" />
                <span>Corbeille</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="app-mail__content">
          <div className="table-header">
            <div className="table-header__title">
              <img src={ICON_STAR} alt="" />
            </div>
            <div className="table-header__title">
              <img src={ICON_CLIP} alt="" />
            </div>
            <div className="table-header__title">
              <span>Sujet</span>
            </div>
            <div className="table-header__title">
              <img src={ICON_GLASSES} alt="" />
            </div>
            <div className="table-header__title">
              <span>De</span>
            </div>
            <div className="table-header__title">
              <span>Date</span>
            </div>
            <div className="table-header__title" />
          </div>
          <div className="table-content">
            {ubuntu.mails.map((mail, index) => (
              <Row
                ref={(el) => { itemsRef.current[index] = el; }}
                id={mail.id}
                title={mail.title}
                from={mail.from}
                date={mail.date}
                content={mail.content}
                reader={activateMailReader}
              />
            ))}
          </div>
          <div className="table-reader">
            {reader && (
            <div className="table-reader__container">
              <div />
              <div>
                <table>
                  <tr>
                    <th>De</th>
                    <td>{reader.from}</td>
                  </tr>
                  <tr>
                    <th>Sujet</th>
                    <td>{reader.title}</td>
                  </tr>
                  <tr>
                    <th>Pour</th>
                    <td>Vous</td>
                  </tr>
                </table>

                <p>{reader.date}</p>
              </div>
              <div>
                <div>
                  {reader.content}
                </div>
              </div>
            </div>
            )}

          </div>
        </div>
      </div>
      <div className="app-mail__footer">
        <div>
          <img src={ICON_CONNEXION} alt="" />
        </div>
        <div className="app-mail__stats">
          <div>
            <span>Non lu: </span>
            <span>{ubuntu.unboxMails}</span>
          </div>
          <div>
            <span>Total: </span>
            <span>{ubuntu.mails.length}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { useDispatch } from 'react-redux';
import Vis from './Cube';
import { logout } from '../../../reducers/user';
import { restart } from '../../../reducers/app';

export default function Game() {
  const dispatch = useDispatch();

  const handleExitGame = () => {
    dispatch(logout());
    dispatch(restart());
  };

  return (
    <div>
      3D scene
      <Vis />

      <div className="react-container">
        <button type="button" onClick={handleExitGame}>Quitter le jeu</button>
      </div>
    </div>
  );
}

import React from 'react';

import './style.scss';
import { useSelector } from 'react-redux';
import Calendar from './Button/Calendar/Calendar';
import Settings from './Button/Settings/Settings';
import Mail from './Button/Mail/Mail';
import Sound from './Button/Sound/Sound';
import Language from './Button/Language/Language';

/**
 * Topbar component
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function Topbar() {
  const user = useSelector((state) => state.user);

  return (
    // <div className={`screen-topbar ${isWindowMaximise ? 'fullscreen' : ''}`}>
    <div className="screen-topbar">
      {user.token !== null && (
        <Mail />
      )}
      <Language />
      <Sound />
      <Calendar />
      <Settings />
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

export const USERS = [
  {
    username: 'wmalbos',
    password: 'WP4DM1N',
    token: 'PjfIkAPDdtAL6lLPyIoMZ4yujUCrjEaibKlaA7guZpxrEwc3JIJyRTY002xVDvdd',
  },
  {
    username: 'students',
    password: 'Ocr2023',
    token: 'bKniGCsL3SgAn-QNnWU!vf19MGy!J/ljIsE2rRfZd6wK59Xe4A!He=/gQ4l=V!h-',
  },
  {
    username: 'guest',
    password: '',
    token: 'jJqkY8cfA7cRPGtwlHwatxaka2Z5szEOrTi9WzoG1C5XtJPeB6ZHKmlgaAToXd2t',
  },
];

export const user = createSlice({
  name: 'user',
  initialState: {
    username: null,
    token: null,
    loginAttempts: 0,
  },
  reducers: {
    login: (state, action) => {
      let foundUser = false;

      USERS.forEach((item) => {
        if (item.username === action.payload.username
                    && item.password === action.payload.password) {
          state.username = item.username;
          state.token = item.token;
          state.loginAttempts = 0;
          foundUser = true;

          localStorage.setItem('user', JSON.stringify({
            username: item.username, token: item.token,
          }));
        }
      });

      if (!foundUser) {
        const loginAttempts = state.loginAttempts + 1;

        state.username = null;
        state.token = null;
        state.loginAttempts = loginAttempts;

        localStorage.setItem('user', JSON.stringify({
          username: null, token: null, loginAttempts,
        }));
      }
    },

    loginByToken: (state, action) => {
      let foundUser = false;

      USERS.forEach((item) => {
        if (item.username === action.payload.username
                    && item.token === action.payload.token) {
          state.username = item.username;
          state.token = item.token;
          foundUser = true;
        }
      });

      if (!foundUser) {
        state.username = null;
        state.token = null;

        localStorage.removeItem('user');
      }
    },
    logout(state) {
      state.username = null;
      state.token = null;

      localStorage.removeItem('user');
    },
    closeSession(state) {
      state.username = null;
      state.token = null;

      localStorage.removeItem('user');
    },

    sleepSession(state) {
      state.token = null;
      localStorage.setItem('user', JSON.stringify({
        username: state.username, token: null,
      }));
    },

  },
});

export const {
  login, loginByToken, logout, closeSession, sleepSession,
} = user.actions;

export default user.reducer;

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import packageJson from '../../../package.json';
import translations from '../../translations/translations.json';

import {
  LANGUAGES, PAGE_VIEW, setCurrentPage, setLanguage,
} from '../../reducers/app';

import ICON_MALT from './icon-malt.png';
import ICON_LINKEDIN from './icon-linkedin.png';

import './style.scss';

/**
 * Keyboard keys
 */
const DIRECTION = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
};

export const GRUB_CHOICES = {
  UBUNTU: 1,
  RETRO_GAME: 2,
  ROOM: 3,
  SETTINGS: 4,
};

export default function Grub() {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  // Number of GRUB options
  const MAX_CHOICES = 4;

  // OS Versions
  const [version] = useState(`${packageJson.version}`);

  // Active GRUB panel view
  const [panelActive, setPanelActive] = useState(1);

  // Active option highlighted
  const [active, setActive] = useState(1);

  // Option unavailable - show error message
  const [isError, setIsError] = useState(false);

  // Exit GRUB
  const [exit, setExit] = useState(false);

  const handleSelectLanguage = (language) => () => {
    dispatch(setLanguage(language));
  };

  /**
   * Change GRUB active panel
   */
  const switchPanelActive = (panel) => {
    setPanelActive(panel);
  };

  /**
   * Try to change current OS highlighted
   */
  const selectActive = () => {
    if (active === GRUB_CHOICES.UBUNTU) {
      setExit(true);
      setTimeout(() => {
        dispatch(setCurrentPage(PAGE_VIEW.UBUNTU_LOADER));
      }, 750);
      return;
    }

    if (active === GRUB_CHOICES.RETRO_GAME) {
      setExit(true);
      setTimeout(() => {
        dispatch(setCurrentPage(PAGE_VIEW.RETRO_LOADER));
      }, 750);
      return;
    }

    if (active === GRUB_CHOICES.ROOM) {
      setExit(true);
      setTimeout(() => {
        dispatch(setCurrentPage(PAGE_VIEW.ROOM_LOADER));
      }, 750);
      return;
    }

    if (active === GRUB_CHOICES.SETTINGS) {
      switchPanelActive(2);
      return;
    }

    setIsError(true);
  };

  /**
     * Select highlighted option with mouse
     */
  const handleMouseClick = (choice) => () => {
    setIsError(false);
    setActive(choice);
    selectActive();
  };

  /**
     * Switch highlighted option
     */
  const moveActiveFocus = (direction) => {
    setIsError(false);

    if (direction === DIRECTION.UP) {
      if (active <= 1) {
        setActive(MAX_CHOICES);
      } else {
        setActive(active - 1);
      }
    }

    if (direction === DIRECTION.DOWN) {
      if (active >= MAX_CHOICES) {
        setActive(1);
      } else {
        setActive(active + 1);
      }
    }
  };

  /**
     * Switch highlighted option with mouse
     */
  const handleMouseHover = (choice) => () => {
    setIsError(false);
    setActive(choice);
  };

  /**
     * Switch highlighted option with keyboard
     */
  const handleKeypress = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        moveActiveFocus(DIRECTION.UP);
        break;
      case 'ArrowDown':
        moveActiveFocus(DIRECTION.DOWN);
        break;
      case 'Enter':
        selectActive();
        break;
      default:
    }
  };

  /**
     * Handle keyboard events
     */
  useEffect(() => {
    document.addEventListener('keydown', handleKeypress, false);

    return () => {
      document.removeEventListener('keydown', handleKeypress, false);
    };
  }, [handleKeypress]);

  return (
    <div className={`screen-grub ${exit ? 'screen-exit' : ''}`} role="presentation">
      <div className="screen-grub__container">
        <p className="screen-grub__socials">
          {translations.GRUB_SOCIAL_NETWORKS[app.language]}
          <a className="screen-grub__networks" href="https://www.malt.fr/profile/wmalbos">
            <img src={ICON_MALT} alt="Profil Malt" className="malt" />
            Malt
          </a>
          <a className="screen-grub__networks" href="https://www.linkedin.com/in/wmalbos/">
            <img src={ICON_LINKEDIN} alt="Profil LinkedIn" className="linkedin" />
            LinkedIn
          </a>
        </p>
        <p className="screen-grub__version">{`Version ${version}`}</p>
        <h1 className="screen-grub__title">William Malbos</h1>
        <p className="screen-grub__job">{translations.GRUB_PORTFOLIO_SUBTITLE[app.language]}</p>

        {panelActive === 1 && (
        <>
          <div className="screen-grub__list">
            <button
              type="button"
              className={`screen-grub__list__item btn-grub ${active === 1 ? 'active' : ''}`}
              onMouseEnter={handleMouseHover(1)}
              onClick={handleMouseClick(1)}
            >
              {active === 1 && <span className="asterisk">*</span>}
              Ubuntu
              <span className="unavailable">(Web OS)</span>
            </button>

            <button
              type="button"
              className={`screen-grub__list__item btn-grub ${active === 2 ? 'active' : ''}`}
              onMouseEnter={handleMouseHover(2)}
              onClick={handleMouseClick(2)}
            >
              {active === 2 && <span className="asterisk">*</span>}
              The Legend of Folio
              <span className="unavailable">(RPG 2D)</span>
            </button>
            <button
              type="button"
              className={`screen-grub__list__item btn-grub ${active === 3 ? 'active' : ''}`}
              onMouseEnter={handleMouseHover(3)}
              onClick={handleMouseClick(3)}
            >
              {active === 3 && <span className="asterisk">*</span>}
              The virtual museum tour
              <span className="unavailable">(Showroom 3D)</span>
            </button>
            <button
              type="button"
              className={`screen-grub__list__item btn-grub btn-settings ${active === 4 ? 'active' : ''}`}
              onMouseEnter={handleMouseHover(4)}
              onClick={handleMouseClick(4)}
            >
              {active === 4 && <span className="asterisk">*</span>}
              {translations.GRUB_SETTINGS_OPTION_TITLE[app.language]}
            </button>

            {isError && (
            <p className="screen-grub__error">
              {translations.GRUB_OPTIONS_ERROR[app.language]}
            </p>
            )}
          </div>
          <p className="screen-grub__text">
            {translations.GRUB_EXPLANATIONS[app.language]}
          </p>
        </>
        )}

        {panelActive === 2 && (
        <div className="screen-grub__list">

          <button
            type="button"
            className={`screen-grub__list__item btn-grub ${active === 5 ? 'active' : ''}`}
            onMouseEnter={handleMouseHover(5)}
            onClick={() => switchPanelActive(1)}
          >
            {active === 5 && <span className="asterisk">*</span>}
            {translations.GRUB_BUTTON_RETURN[app.language]}
          </button>

          <div>
            <p>
              <span>{translations.GRUB_SETTINGS_LANGUAGES_TITLE[app.language]}</span>
              {app.language === LANGUAGES.FRENCH
                  && (<span>{translations.LANGUAGE_FRENCH[app.language]}</span>)}
              {app.language === LANGUAGES.ENGLISH
                  && (<span>{translations.LANGUAGE_ENGLISH[app.language]}</span>)}
            </p>
            <button type="button" className="btn-switch" onClick={handleSelectLanguage(LANGUAGES.FRENCH)}>Français</button>
            <button type="button" className="btn-switch" onClick={handleSelectLanguage(LANGUAGES.ENGLISH)}>Anglais</button>
          </div>
          <div />
        </div>
        )}
        <p className="in-progress">
          <span>{translations.GRUB_DEVELOPMENT_TEXT[app.language]}</span>
        </p>
      </div>
    </div>
  );
}

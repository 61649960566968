import { createSlice } from '@reduxjs/toolkit';

export const windows = createSlice({
  name: 'windows',
  initialState: {
    topIndex: 1,
    active: 0,
    list: [],
    position: {
      xDirection: 1,
      yDirection: 1,
      xOffset: 0,
      yOffset: 0,
    },
  },
  reducers: {
    create: (state, action) => {
      // Modification de la fenêtre active
      const newCurrentActiveWindow = state.active + 1;
      state.active = newCurrentActiveWindow;

      // Modification du zIndex
      const newTopIndex = state.topIndex + 1;
      state.topIndex = newTopIndex;

      // Modification de la position de la fenêtre
      if (state.position.xOffset > 100) {
        state.position.xDirection = -1;
      }

      if (state.position.xOffset < -80) {
        state.position.xDirection = 1;
      }

      if (state.position.yOffset > 160) {
        state.position.yDirection = -1;
      }

      if (state.position.yOffset < -30) {
        state.position.yDirection = 1;
      }

      state.position = {
        ...state.position,
        xOffset: state.position.xOffset + (state.position.xDirection * 20),
        yOffset: state.position.yOffset + (state.position.yDirection * 20),
      };

      // Création de la fenêtre
      const newWindow = {
        ...action.payload,
        windowID: newCurrentActiveWindow,
        xPosition: state.position.xOffset,
        yPosition: state.position.yOffset,
        zIndex: newTopIndex,
        isReduced: false,
        isMaximized: false,
      };

      state.list.push(newWindow);
    },
    close: (state, action) => {
      state.list = state.list.filter((item) => item.windowID !== action.payload.windowID);
    },
    reduce: () => {
      console.log('reduce');
    },
    upgradeWindowPosition(state, action) {
      state.list.map((item) => {
        if (item.windowID === action.payload) {
          const newTopIndex = state.topIndex + 1;
          state.topIndex = newTopIndex;

          item.zIndex = newTopIndex;
        }
        return item;
      });
    },
    reset: (state) => {
      state.topIndex = 1;
      state.active = 0;
      state.list = [];
      state.position = {
        xDirection: 1,
        yDirection: 1,
        xOffset: 0,
        yOffset: 0,
      };
    },
  },
});

export const {
  create, close, reduce, upgradeWindowPosition, reset,
} = windows.actions;

export default windows.reducer;

import { createSlice } from '@reduxjs/toolkit';

export const retro = createSlice({
  name: 'retro',
  initialState: {
    loader: true,
  },
  reducers: {
    setLoader: (state, action) => {
      state.select = action.payload;
    },

  },
});

export const {
  setLoader,
} = retro.actions;

export default retro.reducer;

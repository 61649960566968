import { createSlice } from '@reduxjs/toolkit';

export const room = createSlice({
  name: 'room',
  initialState: {
    loader: true,
  },
  reducers: {
    setLoader: (state, action) => {
      state.select = action.payload;
    },

  },
});

export const {
  setLoader,
} = room.actions;

export default room.reducer;

import Phaser from 'phaser';

const RESSOURCES_SOUNDS_PATH = 'assets/sounds/';

export default class DropItem extends Phaser.Physics.Matter.Sprite {
  static preload(scene) {
    scene.load.audio('pickup', `${RESSOURCES_SOUNDS_PATH}pickup.mp3`);
  }

  constructor({
    scene, x, y, frame,
  }) {
    super(scene.matter.world, x, y, 'items', frame);
    this.scene.add.existing(this);

    const { Bodies } = Phaser.Physics.Matter.Matter;
    const circleCollider = Bodies.circle(this.x, this.y, 12, { isSensor: false, label: 'collider' });
    this.setExistingBody(circleCollider);
    this.setFrictionAir(1);
    this.setScale(0.5);
    this.sound = this.scene.sound.add('pickup');
  }

  pickup() {
    this.sound.play();
    this.destroy();
    return true;
  }
}

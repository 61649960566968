import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import translations from '../../../translations/translations.json';

import SOUND_ENTER from '../../../sounds/dialog-question.wav';

import './style.scss';
import { PAGE_VIEW, setCurrentPage } from '../../../reducers/app';
import { login } from '../../../reducers/user';

import BG_01 from './images/01.jpeg';
import LOGO from './logo.png';

import Topbar from '../Topbar/Topbar';

export const PANELS = {
  GUEST: 'guest',
  STUDENTS: 'students',
  WMALBOS: 'wmalbos',
};

export default function Login() {
  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);

  const refStudentsPassword = useRef(null);
  const refMyPassword = useRef(null);

  const [activePanel, setActivePanel] = useState(PANELS.GUEST);

  const [myPassword, setMyPassword] = useState('');
  const [studentsPassword, setStudentsPassword] = useState('');

  const [isInvalideCredentials, setIsInvalideCredentials] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConnect = (event) => {
    event.preventDefault();

    setIsInvalideCredentials(false);

    let username = '';
    let password = '';

    if (activePanel === PANELS.GUEST) {
      username = 'guest';
      password = '';
    }

    if (activePanel === PANELS.STUDENTS) {
      username = 'students';
      password = studentsPassword;
    }

    if (activePanel === PANELS.WMALBOS) {
      username = 'wmalbos';
      password = myPassword;
    }

    dispatch(login({ username, password }));
  };

  useEffect(() => {
    if (user.token !== null) {
      dispatch(setCurrentPage(PAGE_VIEW.UBUNTU));

      setTimeout(() => {
        const audioEnter = new Audio(SOUND_ENTER);
        audioEnter.volume = 0.2;
        audioEnter.play();
      }, 250);
    } else {
      setIsInvalideCredentials(true);
    }
  }, [user]);

  const switchActivePanel = (newActivePanel) => {
    setIsInvalideCredentials(false);
    setActivePanel(newActivePanel);
  };

  /**
     * Switch highlighted option with keyboard
     */
  const handleKeypress = (event) => {
    switch (event.key) {
      case 'ArrowUp':

        if (activePanel === PANELS.STUDENTS) {
          switchActivePanel(PANELS.GUEST);
        }

        if (activePanel === PANELS.WMALBOS) {
          switchActivePanel(PANELS.STUDENTS);
        }

        if (activePanel === PANELS.GUEST) {
          switchActivePanel(PANELS.WMALBOS);
        }

        break;
      case 'ArrowDown':

        if (activePanel === PANELS.STUDENTS) {
          switchActivePanel(PANELS.WMALBOS);
        }

        if (activePanel === PANELS.WMALBOS) {
          switchActivePanel(PANELS.GUEST);
        }

        if (activePanel === PANELS.GUEST) {
          switchActivePanel(PANELS.STUDENTS);
        }

        break;
      case 'Enter':
        handleConnect(event);
        break;
      default:
    }
  };

  useEffect(() => {
    if (activePanel === PANELS.STUDENTS) {
      refStudentsPassword.current.focus();
    }

    if (activePanel === PANELS.WMALBOS) {
      refMyPassword.current.focus();
    }
  }, [activePanel]);
  /**
     * Handle keyboard events
     */
  useEffect(() => {
    document.addEventListener('keydown', handleKeypress, false);

    return () => {
      document.removeEventListener('keydown', handleKeypress, false);
    };
  }, [handleKeypress]);

  return (
    <div className="screen-login">

      <Topbar />

      <div className="screen-background">
        <img src={BG_01} alt="" className="screen-background__image" />
      </div>

      <div className="container">
        <form onSubmit={handleConnect} className="form-login">

          {activePanel !== PANELS.WMALBOS && (
            <div className="other-session">
              <button
                type="button"
                onClick={() => switchActivePanel(PANELS.WMALBOS)}
              >
                {translations.UBUNTU_LOGIN_WMALBOS_TITLE[app.language]}
              </button>
            </div>
          )}
          {activePanel === PANELS.WMALBOS && (
            <div className="block-login">
              <div className="block-login__header">
                <span
                  className="block-login__title"
                >
                  {translations.UBUNTU_LOGIN_WMALBOS_TITLE[app.language]}
                </span>
                <img src={LOGO} alt="" />
              </div>
              <div>
                {isInvalideCredentials
                                    && (
                                    <p className="invalid-credentials">{translations.UBUNTU_LOGIN_WRONG_CREDENTIALS[app.language]}</p>)}
                <label htmlFor="myPassword">
                  <input
                    ref={refMyPassword}
                    id="myPassword"
                    className="input-password"
                    placeholder={translations.UBUNTU_LOGIN_INPUT_PASSWORD[app.language]}
                    type={showPassword ? 'text' : 'password'}
                    name="myPassword"
                    onChange={(event) => {
                      setIsInvalideCredentials(false);
                      setMyPassword(event.target.value);
                    }}
                    value={myPassword}
                  />
                </label>

                <button type="button" className={`btn-show-password ${showPassword ? 'active' : ''}`} onClick={handleShowPassword}>
                  {showPassword && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                    />
                  </svg>
                  )}

                  {!showPassword && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                  </svg>
                  )}
                </button>

              </div>
            </div>
          )}

          {activePanel !== PANELS.GUEST && (
            <div className="other-session">
              <button
                type="button"
                onClick={() => switchActivePanel(PANELS.GUEST)}
              >
                {translations.UBUNTU_LOGIN_GUEST_TITLE[app.language]}
              </button>
            </div>
          )}
          {activePanel === PANELS.GUEST && (
            <div className="block-login">
              <div className="block-login__header">
                <span
                  className="block-login__title"
                >
                  {translations.UBUNTU_LOGIN_GUEST_TITLE[app.language]}
                </span>
                <img src={LOGO} alt="" />
              </div>
              <div>
                <button type="button" className="btn-guest--connect" onClick={handleConnect}>
                  <span>{translations.UBUNTU_LOGIN_BUTTON_CONNECT[app.language]}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path
                      d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}

          {activePanel !== PANELS.STUDENTS && (
            <div className="other-session">
              <button
                type="button"
                onClick={() => switchActivePanel(PANELS.STUDENTS)}
              >
                {translations.UBUNTU_LOGIN_STUDENTS_TITLE[app.language]}
              </button>
            </div>
          )}
          {activePanel === PANELS.STUDENTS && (
            <div className="block-login">
              <div className="block-login__header">
                <span
                  className="block-login__title"
                >
                  {translations.UBUNTU_LOGIN_STUDENTS_TITLE[app.language]}
                </span>
                <img src={LOGO} alt="" />
              </div>
              <div>
                {isInvalideCredentials
                                    && (
                                    <p className="invalid-credentials">{translations.UBUNTU_LOGIN_WRONG_CREDENTIALS[app.language]}</p>)}
                <label htmlFor="studentsPassword">
                  <input
                    ref={refStudentsPassword}
                    id="studentsPassword"
                    className="input-password"
                    placeholder={translations.UBUNTU_LOGIN_INPUT_PASSWORD[app.language]}
                    type={showPassword ? 'text' : 'password'}
                    name="studentsPassword"
                    onChange={(event) => {
                      setIsInvalideCredentials(false);
                      setStudentsPassword(event.target.value);
                    }}
                    value={studentsPassword}
                  />
                </label>

                <button type="button" className={`btn-show-password ${showPassword ? 'active' : ''}`} onClick={handleShowPassword}>
                  {showPassword && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                    />
                  </svg>
                  )}

                  {!showPassword && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                  </svg>
                  )}
                </button>
              </div>
            </div>
          )}

        </form>
      </div>
    </div>
  );
}

import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setSoundVolume } from '../../../../../reducers/app';

import ICON_SOUND from './icon-sound.png';

import translations from '../../../../../translations/translations.json';

import './style.scss';

export default function Sound() {
  const dispatch = useDispatch();
  const refDropdown = useRef(null);

  const app = useSelector((state) => state.app);
  const [isDropdownActive, setIsDropdownActive] = React.useState(false);

  const handleSwitchDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const switchSoundVolume = () => {
    dispatch(setSoundVolume(100));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsDropdownActive(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  return (
    <div className="topbar-sound" ref={refDropdown}>
      <button type="button" className="topbar__button  btn-sound" onClick={handleSwitchDropdown}>
        <img src={ICON_SOUND} alt="" />
      </button>

      {isDropdownActive && (
        <div className="dropdown">
          <button type="button" onClick={switchSoundVolume}>{translations.UBUNTU_TOPBAR_SOUND_STOP[app.language]}</button>
        </div>
      )}
    </div>
  );
}

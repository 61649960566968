import Phaser from 'phaser';
import MatterEntity from './MatterEntity';

const DEFAULT_CHARACTERS_PATH = 'assets/images/characters/';
const DEFAULT_ITEMS_PATH = 'assets/images/objects/';
const SOUNDS_PATH = 'assets/sounds/';

const ITEMS = {
  PICKAXE: 162,
  DEATH: 0,
};
export default class Player extends MatterEntity {
  static preload(scene) {
    scene.load.atlas('female', `${DEFAULT_CHARACTERS_PATH}female.png`, `${DEFAULT_CHARACTERS_PATH}female_atlas.json`);
    scene.load.animation('female_anim', `${DEFAULT_CHARACTERS_PATH}female_anim.json`);

    scene.load.spritesheet('items', `${DEFAULT_ITEMS_PATH}items.png`, { frameWidth: 32, frameHeight: 32 });

    scene.load.audio('player', `${SOUNDS_PATH}player.mp3`);
  }

  constructor(data) {
    const {
      scene,
    } = data;
    super({
      ...data,
      health: 2,
      drops: [],
      name: 'player',
    });

    // Define collider and sensor
    const { Body, Bodies } = Phaser.Physics.Matter.Matter;
    const playerCollider = Bodies.circle(this.x, this.y, 12, { isSensor: false, label: 'playerCollider' });
    const playerSensor = Bodies.circle(this.x, this.y, 24, { isSensor: true, label: 'playerSensor' });
    const compoundBody = Body.create({ parts: [playerCollider, playerSensor], frictionAir: 0.35 });

    this.setExistingBody(compoundBody);
    this.setFixedRotation();
    this.setOrigin(0, 0);

    // Define cursor movments
    this.scene.input.on('pointermove', (cursor) => {
      cursor.x += this.scene.cameras.main.scrollX;
      cursor.y += this.scene.cameras.main.scrollY;
      if (!this.dead) this.setFlipX(cursor.worldX < this.x);
    });

    // Create weapon
    this.spriteWeapon = new Phaser.GameObjects.Sprite(scene, 0, 0, 'items', ITEMS.PICKAXE);
    this.spriteWeapon.setOrigin(0.25, 0.75);
    this.spriteWeapon.setScale(0.6);
    this.scene.add.existing(this.spriteWeapon);

    // Define mining
    this.touching = [];
    this.createMiningCollisions(playerSensor);

    // Define item pickup
    this.createPickupCollisions(playerCollider);
  }

  update() {
    if (this.dead) return;

    const speed = 2.5;
    const playerVelocity = new Phaser.Math.Vector2();

    if (this.inputKeys.left.isDown) {
      playerVelocity.x = -1;
    } else if (this.inputKeys.right.isDown) {
      playerVelocity.x = 1;
    }

    if (this.inputKeys.up.isDown) {
      playerVelocity.y = -1;
    } else if (this.inputKeys.down.isDown) {
      playerVelocity.y = 1;
    }

    // Normalize the velocity so that the player is not faster diagonally
    playerVelocity.normalize();

    playerVelocity.scale(speed);
    this.setVelocity(playerVelocity.x, playerVelocity.y);

    // Manage animations
    if (Math.abs(this.velocity.x) > 0.1 || Math.abs(this.velocity.y) > 0.1) {
      this.anims.play('female_walk', true);
    } else {
      this.anims.play('female_idle', true);
    }

    // Position of the weapon
    this.spriteWeapon.setPosition(this.x, this.y);
    this.weaponRotate();
  }

  weaponRotate() {
    const pointer = this.scene.input.activePointer;

    if (pointer.isDown) {
      this.weaponRotation += 6;
    } else {
      this.weaponRotation = 0;
    }

    if (this.weaponRotation > 100) {
      this.whackStuff();
      this.weaponRotation = 0;
    }

    if (this.flipX) {
      this.spriteWeapon.setAngle(-this.weaponRotation - 90);
    } else {
      this.spriteWeapon.setAngle(this.weaponRotation);
    }
  }

  createMiningCollisions(playerSensor) {
    this.scene.matterCollision.addOnCollideStart({
      objectA: playerSensor,
      callback: (other) => {
        if (other.bodyB.isSensor) return;
        this.touching.push(other.gameObjectB);
      },
      context: this.scene,
    });

    this.scene.matterCollision.addOnCollideEnd({
      objectA: playerSensor,
      callback: (other) => {
        this.touching = this.touching.filter((gameObject) => gameObject !== other.gameObjectB);
      },
      context: this.scene,
    });
  }

  whackStuff() {
    this.touching = this.touching.filter((gameObject) => gameObject.hit && !gameObject.dead);
    this.touching.forEach((gameObject) => {
      gameObject.hit();
      if (gameObject.dead) gameObject.destroy();
    });
  }

  createPickupCollisions(playerCollider) {
    this.scene.matterCollision.addOnCollideStart({
      objectA: playerCollider,
      callback: (other) => {
        if (other.gameObjectB && other.gameObjectB.pickup) {
          other.gameObjectB.pickup();
        }
      },
      context: this.scene,
    });

    this.scene.matterCollision.addOnCollideActive({
      objectA: playerCollider,
      callback: (other) => {
        if (other.gameObjectB && other.gameObjectB.pickup) {
          other.gameObjectB.pickup();
        }
      },
      context: this.scene,
    });
  }

  onDeath() {
    this.anims.stop();
    this.setTexture('items', ITEMS.DEATH);
    this.setOrigin(0.5);
    this.spriteWeapon.destroy();
  }
}

import React, { useEffect } from 'react';

import './style.scss';

import Phaser from 'phaser';
import { PhaserMatterCollisionPlugin } from 'phaser-matter-collision-plugin';
import { useDispatch } from 'react-redux';
import MainScene from './scenes/MainScene';
import { logout } from '../../../reducers/user';
import { restart } from '../../../reducers/app';

export default function Game() {
  const dispatch = useDispatch();

  const startGame = () => new Phaser.Game({
    type: Phaser.AUTO,
    parent: 'phaser-container',
    backgroundColor: '#282c34',
    scale: {
      // width: window.innerWidth,
      // height: window.innerHeight,
      width: 32 * 16,
      height: 32 * 16,
      zoom: 2,
    },
    scene: [MainScene],
    physics: {
      default: 'matter',
      matter: {
        // debug: true,
        gravity: { y: 0 },
      },
    },
    plugins: {
      scene: [
        {
          plugin: PhaserMatterCollisionPlugin,
          key: 'matterCollision',
          mapping: 'matterCollision',
        },
      ],
    },
  });

  const handleExitGame = () => {
    dispatch(logout());
    dispatch(restart());
  };
  useEffect(() => {
    startGame();
  }, []);

  return (
    <>
      <div id="phaser-container" />
      <div className="react-container">
        <button type="button" onClick={handleExitGame}>Quitter le jeu</button>
      </div>
    </>
  );
}

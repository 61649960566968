import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  closeSession, login, logout, sleepSession,
} from '../../../../../reducers/user';

import translations from '../../../../../translations/translations.json';

import ICON_POWER from './icon-power.png';

import './style.scss';
import { PAGE_VIEW, restart, setCurrentPage } from '../../../../../reducers/app';
import { create as newWindow, reset } from '../../../../../reducers/windows';
import WINDOWS_TYPE from '../../../window/Type';

export default function Settings() {
  const dispatch = useDispatch();
  const refDropdown = useRef(null);
  const app = useSelector((state) => state.app);

  const [isDropdownActive, setIsDropdownActive] = React.useState(false);

  const handleSwitchDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const handleOpenAbout = () => {
    dispatch(newWindow({
      type: WINDOWS_TYPE.ABOUT,
    }));
    setIsDropdownActive(false);
  };

  const handleOpenSettings = () => {
    dispatch(newWindow({
      type: WINDOWS_TYPE.SETTINGS,
    }));
    setIsDropdownActive(false);
  };

  const handleOpenHelp = () => {
    dispatch(newWindow({
      type: WINDOWS_TYPE.GUIDE_WELCOME,
    }));
    setIsDropdownActive(false);
  };

  const handleCloseSession = () => {
    dispatch(closeSession());
    dispatch(setCurrentPage(PAGE_VIEW.UBUNTU_LOGIN));
    dispatch(reset());
    setIsDropdownActive(false);
  };

  const handleStandbySession = () => {
    dispatch(sleepSession());
    dispatch(setCurrentPage(PAGE_VIEW.UBUNTU_SCREENSAVER));
    setIsDropdownActive(false);
  };

  const handleRestart = () => {
    dispatch(logout());
    dispatch(restart());
  };

  const handleSwitchGuest = () => {
    dispatch(login({ username: 'guest', password: '' }));
    dispatch(setCurrentPage(PAGE_VIEW.UBUNTU_LOADER));
    setIsDropdownActive(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsDropdownActive(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  /**
   * Switch highlighted option with keyboard
   */
  const handleKeypress = (event) => {
    if ((event.altKey && event.shiftKey && event.key === 'l')
        || (event.altKey && event.shiftKey && event.key === '|')) {
      handleStandbySession();
    }

    if ((event.altKey && event.shiftKey && event.key === 'i')
        || (event.altKey && event.shiftKey && event.key === 'ï')) {
      handleSwitchGuest();
    }

    if ((event.altKey && event.shiftKey && event.key === 'c')
        || (event.altKey && event.shiftKey && event.key === '¢')) {
      handleCloseSession();
    }
  };

  /**
   * Handle keyboard events
   */
  useEffect(() => {
    document.addEventListener('keydown', handleKeypress, false);

    return () => {
      document.removeEventListener('keydown', handleKeypress, false);
    };
  }, [handleKeypress]);

  return (
    <div className="topbar-settings" ref={refDropdown}>
      <button type="button" className="topbar__button  btn-settings" onClick={handleSwitchDropdown}>
        <img src={ICON_POWER} alt="" />
      </button>

      {isDropdownActive && (
        <div className="dropdown">
          <button type="button" className={`btn-switch ${app.currentPage === PAGE_VIEW.UBUNTU_SCREENSAVER || app.currentPage === PAGE_VIEW.UBUNTU_LOGIN ? 'disabled' : ''}`} onClick={handleOpenAbout}>{translations.UBUNTU_TOPBAR_SETTINGS_ABOUT[app.language]}</button>
          <button type="button" className={`btn-switch  ${app.currentPage === PAGE_VIEW.UBUNTU_SCREENSAVER || app.currentPage === PAGE_VIEW.UBUNTU_LOGIN ? 'disabled' : ''}`} onClick={handleOpenHelp}>{translations.UBUNTU_TOPBAR_SETTINGS_HELP[app.language]}</button>
          <div className="divider" />
          <button type="button" className={`btn-switch  ${app.currentPage === PAGE_VIEW.UBUNTU_SCREENSAVER || app.currentPage === PAGE_VIEW.UBUNTU_LOGIN ? 'disabled' : ''}`} onClick={handleOpenSettings}>{translations.UBUNTU_TOPBAR_SETTINGS_PARAMETERS[app.language]}</button>
          <div className="divider" />
          <button type="button" className={`btn-switch ${app.currentPage === PAGE_VIEW.UBUNTU_SCREENSAVER || app.currentPage === PAGE_VIEW.UBUNTU_LOGIN ? 'disabled' : ''}`} onClick={handleStandbySession}>
            <span>{translations.UBUNTU_TOPBAR_SETTINGS_LOCK[app.language]}</span>
            <span className="shortcut">Ctrl+Alt+V</span>
          </button>
          <button type="button" className={`btn-switch  ${app.currentPage === PAGE_VIEW.UBUNTU_SCREENSAVER || app.currentPage === PAGE_VIEW.UBUNTU_LOGIN ? 'disabled' : ''}`} onClick={handleSwitchGuest}>
            <span>{translations.UBUNTU_TOPBAR_SETTINGS_GUEST[app.language]}</span>
            <span className="shortcut">Ctrl+Alt+I</span>
          </button>
          <div className="divider" />
          <button type="button" className="btn-switch " onClick={handleCloseSession}>
            <span>{translations.UBUNTU_TOPBAR_SETTINGS_CLOSE_SESSION[app.language]}</span>
            <span className="shortcut">Ctrl+Alt+C</span>
          </button>
          <div className="divider" />
          <button type="button" className={`btn-switch ${app.currentPage === PAGE_VIEW.UBUNTU_SCREENSAVER || app.currentPage === PAGE_VIEW.UBUNTU_LOGIN ? 'disabled' : ''}`} onClick={handleStandbySession}>{translations.UBUNTU_TOPBAR_SETTINGS_STANDBY[app.language]}</button>
          <button type="button" className="btn-switch" onClick={handleRestart}>{translations.UBUNTU_TOPBAR_SETTINGS_RESTART[app.language]}</button>
          <button type="button" className="btn-switch" onClick={handleRestart}>{translations.UBUNTU_TOPBAR_SETTINGS_LOGOUT[app.language]}</button>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PAGE_VIEW, setCurrentPage } from '../../../reducers/app';
import Logo from '../../Ubuntu/Loader/logo.png';

export default function Loader() {
  const dispatch = useDispatch();
  const [isLoadingHide, setIsLoadingHide] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingHide(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);

      setTimeout(() => {
        dispatch(setCurrentPage(PAGE_VIEW.ROOM_MENU));
      }, 250);
    }, 4250);
  }, []);

  return isLoading && (
    <div className={`screen-loader ${isLoadingHide ? 'hide' : ''}`}>
      <div>
        <div className="screen-loader__logo">
          <div className="screen-loader__logo__icon" />
          <img src={Logo} alt="Portfolio - William Malbos" />
        </div>

        <div className="screen-loader__dots">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

export const PAGE_VIEW = {
  GRUB: 'GRUB',

  UBUNTU_LOADER: 'UBUNTU_LOADER',
  UBUNTU_LOGIN: 'UBUNTU_LOGIN',
  UBUNTU_SCREENSAVER: 'UBUNTU_SCREENSAVER',
  UBUNTU: 'UBUNTU',

  RETRO_LOADER: 'RETRO',
  RETRO_MENU: 'RETRO_MENU',
  RETRO_GAME: 'RETRO_GAME',

  ROOM_LOADER: 'ROOM',
  ROOM_MENU: 'ROOM_MENU',
  ROOM_GAME: 'ROOM_GAME',
};

export const LANGUAGES = {
  ENGLISH: 'en',
  FRENCH: 'fr',
};

export const app = createSlice({
  name: 'app',
  initialState: {
    currentPage: PAGE_VIEW.GRUB,
    language: LANGUAGES.ENGLISH,
    soundVolume: 0,
    isLoading: true,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
      localStorage.setItem('currentPage', action.payload);
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },
    setSoundVolume: (state, action) => {
      state.soundVolume = action.payload;
      localStorage.setItem('soundVolume', action.payload);
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    restart: () => {
      localStorage.removeItem('currentPage');
      window.location.reload();
    },
  },
});

export const {
  setCurrentPage, setLanguage, setSoundVolume, setIsLoading, restart,
} = app.actions;

export default app.reducer;

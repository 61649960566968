import React, { useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

export default function Calendar() {
  const refDropdown = useRef(null);
  const refTimer = useRef(null);
  const app = useSelector((state) => state.app);

  const [isDropdownActive, setIsDropdownActive] = React.useState(false);

  const handleSwitchDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsDropdownActive(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  const setCurrentDate = () => {
    const newDate = new Date();
    let result = null;
    if (app.language === 'en') {
      result = newDate.toLocaleDateString('en-GB', {
        month: 'long',
        weekday: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } else if (app.language === 'jp') {
      result = newDate.toLocaleDateString('ja-JP', {
        month: 'long',
        weekday: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } else {
      result = newDate.toLocaleDateString('fr-FR', {
        month: 'long',
        weekday: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }

    refTimer.current.innerHTML = result;
  };

  useEffect(() => {
    setCurrentDate();

    const interval = setInterval(() => {
      setCurrentDate();
    }, 1000);

    return () => clearInterval(interval);
  }, [app.language]);

  return (
    <div className="topbar-calendar" ref={refDropdown}>
      <button type="button" className="topbar__button btn-calendar" onClick={handleSwitchDropdown}>
        <span ref={refTimer} />
      </button>

      {isDropdownActive && (
        <div className="dropdown">
          Calenar
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import BtnCategory from './BtnCategory';

import ICON_HOME from './icon-home.png';
import ICON_FILE from './icon-file.png';
import ICON_MUSIC from './icon-music.png';
import ICON_DESKTOP from './icon-desktop.png';
import ICON_IMAGES from './icon-images.png';
import ICON_TRASH from './icon-trash.png';
import ICON_DOWNLOADS from './icon-downloads.png';
import ICON_VIDEOS from './icon-videos.png';

import './style.scss';

const FINDER_ELEMENT_TYPE = {
  PANEL: 'panel',
  FOLDER: 'folder',
  FILE: 'file',
};

const FINDER_ELEMENTS = [
  {
    id: 1,
    type: FINDER_ELEMENT_TYPE.PANEL,
    name: 'Documents',
    children: [
      {
        id: 2,
        type: FINDER_ELEMENT_TYPE.FOLDER,
        name: 'Local',
        children: [
          {
            id: 5,
            type: FINDER_ELEMENT_TYPE.FOLDER,
            name: 'Canonical',
          },
        ],
      },
      {
        id: 3,
        type: FINDER_ELEMENT_TYPE.FOLDER,
        name: 'Sauvegarde',
        children: [
          {
            id: 6,
            type: FINDER_ELEMENT_TYPE.FOLDER,
            name: 'Canonical',
          },
          {
            id: 7,
            type: FINDER_ELEMENT_TYPE.FOLDER,
            name: 'Canonical',
          },
        ],
      },
      {
        id: 4,
        type: FINDER_ELEMENT_TYPE.FOLDER,
        name: 'Canonical',
      },
    ],
  },

];
export default function Finder() {
  const [activePanel, setActivePanel] = useState(1);
  const [currentPanel, setCurrentPanel] = useState(FINDER_ELEMENTS[0]);

  const findRightPanel = (id, start) => start.find((element) => {
    if (element.id === id) {
      return element;
    }
    if (element.children) {
      return findRightPanel(id, element.children);
    }
    return null;
  });

  const handleOpenFolder = (id) => {
    const panel = findRightPanel(id, FINDER_ELEMENTS);

    console.log(panel);
    setCurrentPanel(panel);
    setActivePanel(id);
  };

  useEffect(() => {
    const panel = findRightPanel(1, FINDER_ELEMENTS);

    setActivePanel(activePanel);
    setCurrentPanel(panel);
  }, []);

  return (
    <div className="app-finder">
      <div className="app-finder__topbar">
        <div className="window-app__controls">
          <button type="button" className={`btn ${currentPanel?.parent ? 'active' : 'disabled'}`}>
            Previous
          </button>
          <button type="button" className={`btn ${currentPanel?.parent ? 'active' : 'disabled'}`}>next</button>
        </div>

        <div className="window-app__breadcrumb">
          <button type="button">Home</button>
          <button type="button">{currentPanel?.name}</button>
        </div>
      </div>
      <div className="app-finder__container">
        <div className="app-finder__sidebar">
          <h2 className="sidebar__title"> Raccourics</h2>
          <ul className="sidebar__list">
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_HOME} alt="" />
                <span> Dossier personnel</span>
              </button>
            </li>
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_DESKTOP} alt="" />
                <span>Bureau</span>
              </button>
            </li>
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_FILE} alt="" />
                <span>Documents</span>
              </button>
            </li>
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_IMAGES} alt="" />
                <span>Images</span>
              </button>
            </li>
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_MUSIC} alt="" />
                <span>Musique</span>
              </button>
            </li>
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_DOWNLOADS} alt="" />
                <span>Téléchargements</span>
              </button>
            </li>
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_VIDEOS} alt="" />
                <span>Vidéos</span>
              </button>
            </li>
            <li>
              <button type="button" className="sidebar__button">
                <img src={ICON_TRASH} alt="" />
                <span>Corbeille</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="app-finder__content">
          <div key={currentPanel?.id} className="app-finder__panel">
            {currentPanel?.children?.map((element) => (
              <BtnCategory
                key={element.id}
                id={element.id}
                name={element.name}
                onOpenFolder={(id) => handleOpenFolder(id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

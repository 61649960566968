import React from 'react';

import PropTypes from 'prop-types';

import ICON_FOLDER from './icon-folder.png';
import ICON_FOLDER_ACTIVE from './icon-folder-active.png';
import ICON_FOLDER_HOVER from './icon-folder-hover.png';

import './style.scss';

export default function BtnCategory({ id, name, onOpenFolder }) {
  const [isActived, setIsActived] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);

  const handleActive = (event) => {
    if (event.detail === 2) {
      onOpenFolder(id);
    } else {
      setIsActived(!isActived);
    }
  };

  const handleHover = (newValue) => () => {
    setIsHover(newValue);
  };

  return (
    <button
      type="button"
      className={`app-finder__btn-category ${isActived ? 'active' : ''}`}
      onClick={handleActive}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
    >
      {isActived ? <img src={ICON_FOLDER_ACTIVE} alt="" /> : ''}
      {!isActived && isHover ? <img src={ICON_FOLDER_HOVER} alt="" /> : ''}
      {!isActived && !isHover ? <img src={ICON_FOLDER} alt="" /> : ''}
      <span>{name}</span>
    </button>
  );
}

BtnCategory.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onOpenFolder: PropTypes.func.isRequired,
};

import React from 'react';

import './style.scss';

import BG_01 from './images/01.jpeg';

export default function Background() {
  return (
    <div className="screen-background">
      <img src={BG_01} alt="" className="screen-background__image" />
    </div>
  );
}

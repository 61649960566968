import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { create as newWindow } from '../../../../reducers/windows';
import WINDOWS_TYPE from '../../window/Type';

/**
 * Guide component
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function Guide() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(newWindow({
      type: WINDOWS_TYPE.GUIDE_WELCOME,
    }));
  }, []);

  return (
    <div className="guide-welcome" />
  );
}

import Phaser from 'phaser';
import MatterEntity from './MatterEntity';

const RESOURCES_PATH = 'assets/images/resources/';
const RESSOURCES_SOUNDS_PATH = 'assets/sounds/';

export default class Resource extends MatterEntity {
  static preload(scene) {
    scene.load.atlas('resources', `${RESOURCES_PATH}resources.png`, `${RESOURCES_PATH}resources_atlas.json`);

    scene.load.audio('bush', `${RESSOURCES_SOUNDS_PATH}bush.mp3`);
    scene.load.audio('rock', `${RESSOURCES_SOUNDS_PATH}rock.mp3`);
    scene.load.audio('tree', `${RESSOURCES_SOUNDS_PATH}tree.mp3`);
  }

  constructor({ scene, resource }) {
    const drops = JSON.parse(resource.properties.find((p) => p.name === 'drops').value);
    const depth = resource.properties.find((p) => p.name === 'depth').value;

    super({
      scene,
      x: resource.x,
      y: resource.y,
      texture: 'resources',
      frame: resource.name,
      drops,
      depth,
      health: 5,
      name: resource.name,
    });

    const yOrigin = resource.properties.find((p) => p.name === 'yOrigin').value;
    this.y += this.height * (yOrigin - 0.5);

    const { Bodies } = Phaser.Physics.Matter.Matter;
    const circleCollider = Bodies.circle(this.x, this.y, 12, { isSensor: false, label: 'collider' });

    this.setExistingBody(circleCollider);
    this.setFixedRotation();
    this.setOrigin(0.5, yOrigin);
    this.setStatic(true);
  }
}

import { createSlice } from '@reduxjs/toolkit';

export const ubuntu = createSlice({
  name: 'ubuntu',
  initialState: {
    loader: true,
    mails: [],
    unboxMails: 0,
    firstStart: true,
  },
  reducers: {
    setLoader: (state, action) => {
      state.select = action.payload;
    },
    setMails: (state, action) => {
      let unbox = 0;
      state.mails.forEach((item) => {
        if (!item.read) {
          unbox += 1;
        }
      });

      action.payload.forEach((item) => {
        if (!item.read) {
          unbox += 1;
        }
      });

      return {
        ...state,
        unboxMails: unbox,
        mails: [...action.payload],

      };
    },
    setMailRead: (state, action) => {
      const mailsRead = [];

      const mails = state.mails.map((item) => {
        if (item.id === action.payload) {
          if (!mailsRead.includes(item.id)) {
            mailsRead.push(item.id);
          }

          return {
            ...item,
            read: true,
          };
        }
        return item;
      });

      let unbox = 0;
      mails.forEach((item) => {
        if (!item.read) {
          unbox += 1;
        } else if (!mailsRead.includes(item.id)) {
          mailsRead.push(item.id);
        }
      });

      localStorage.setItem('mailsRead', JSON.stringify(mailsRead));

      return {
        ...state,
        unboxMails: unbox,
        mails,
      };
    },
    setFirstStart: (state, action) => {
      state.firstStart = action.payload;
      localStorage.setItem('firstUbuntuStart', JSON.stringify(action.payload));
    },
  },
});

export const {
  setLoader, setMails, setMailRead, setFirstStart,
} = ubuntu.actions;

export default ubuntu.reducer;

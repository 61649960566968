import React, { useState } from 'react';

import PropTypes from 'prop-types';

import './style.scss';

/**
 * Dragger component
 *
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export default function Dragger({ children }) {
  const [target, setTarget] = useState(null);
  const [position, setPosition] = useState(null);

  const onStartMoveWindow = (event) => {
    console.log('start');
    if (event.target.querySelector('.window-menu__controls')) {
      console.log('click');
      const currentTarget = event.target.closest('.dragger-container');
      const evt = event.type === 'touchstart' ? event.changedTouches[0] : event;

      if (evt.button === 0) {
        currentTarget.style.pointerEvents = 'none';

        setPosition({
          xPosition: Math.abs(currentTarget.offsetLeft - evt.clientX),
          yPosition: Math.abs(currentTarget.offsetTop - evt.clientY),
        });
        setTarget(currentTarget);
      }
    }
  };

  const onMoveWindow = (event) => {
    if (target) {
      if (target.style.pointerEvents === 'none') {
        target.style.left = `${event.clientX - position.xPosition}px`;
        target.style.top = `${event.clientY - position.yPosition}px`;
      }
    }
  };

  const onStopMoveWindow = () => {
    if (target !== null) {
      target.style.pointerEvents = 'initial';
    }
  };

  return (
    <div
      className="dragger-area"
      onMouseMove={onMoveWindow}
      onTouchMove={onMoveWindow}
      onMouseUp={onStopMoveWindow}
      onTouchEnd={onStopMoveWindow}
      role="presentation"
    >
      {children((e) => onStartMoveWindow(e))}
    </div>
  );
}

Dragger.propTypes = { children: PropTypes.func.isRequired };

import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import ICON_UNREAD from './icon-unread.png';
import ICON_READ from './icon-read.png';
import ICON_UNSTAR from './icon-unstar.png';
import ICON_STAR from './icon-star.png';

const Row = forwardRef(({
  id,
  title,
  from,
  date,
  content,
  reader,
}, ref) => {
  const ubuntu = useSelector((state) => state.ubuntu);

  const [selected, setSelected] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [isStar, setIsStar] = useState(false);

  useImperativeHandle(ref, () => ({
    getID() {
      return id;
    },
    removeSelected() {
      setSelected(false);
    },
  }));

  const handleSelected = () => {
    setSelected(true);
    setIsRead(true);
    reader({
      id, title, from, date, content,
    });
  };

  const handleStar = () => {
    setIsStar(!isStar);
  };

  useEffect(() => {
    ubuntu.mails.forEach((mail) => {
      if (mail.id === id) {
        if (mail.read) {
          setIsRead(true);
        }
      }
    });
  }, [ubuntu]);
  return (
    <div className={`app-mail__row ${selected ? 'select' : ''} ${isRead ? '' : 'unread'}`}>
      <button type="button" className="app-mail__row__column" onClick={handleStar}>
        {isStar && <img src={ICON_STAR} alt="" />}
        {!isStar && <img src={ICON_UNSTAR} alt="" />}
      </button>
      <div className="app-mail__row__column" />
      <button type="button" className="app-mail__row__column" onClick={handleSelected}>{title}</button>
      <button type="button" className="app-mail__row__column" onClick={handleSelected}>
        {isRead && <img src={ICON_READ} alt="" />}
        {!isRead && <img src={ICON_UNREAD} alt="" />}

      </button>
      <button type="button" className="app-mail__row__column" onClick={handleSelected}>{from}</button>
      <button type="button" className="app-mail__row__column" onClick={handleSelected}>{date}</button>
    </div>
  );
});

export default Row;

Row.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  reader: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import Logo from './logo.png';
import SOUND_LOGIN from '../../../sounds/desktop-login.wav';

import './style.scss';
import { PAGE_VIEW, setCurrentPage } from '../../../reducers/app';

/**
 * Screen loader
 *
 * @return {false|JSX.Element}
 * @constructor
 */
export default function Loader() {
  const dispatch = useDispatch();
  const [isLoadingHide, setIsLoadingHide] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const audioLogin = new Audio(SOUND_LOGIN);
    audioLogin.volume = 0.1;
    audioLogin.play();

    setTimeout(() => {
      setIsLoadingHide(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);

      setTimeout(() => {
        dispatch(setCurrentPage(PAGE_VIEW.UBUNTU_LOGIN));
      }, 250);
    }, 4250);
  }, []);

  return isLoading && (
  <div className={`screen-loader ${isLoadingHide ? 'hide' : ''}`}>
    <div>
      <div className="screen-loader__logo">
        <div className="screen-loader__logo__icon" />
        <img src={Logo} alt="Portfolio - William Malbos" />
      </div>

      <div className="screen-loader__dots">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  </div>
  );
}

import React from 'react';

import { useDispatch } from 'react-redux';
import { PAGE_VIEW, setCurrentPage } from '../../../reducers/app';

import './style.scss';

export default function Menu() {
  const dispatch = useDispatch();

  const handleNewGame = () => {
    dispatch(setCurrentPage(PAGE_VIEW.RETRO_GAME));
  };

  const handleExitGame = () => {
    dispatch(setCurrentPage(PAGE_VIEW.GRUB));
  };
  const handleSettingsGame = () => {
    console.log('settings');
  };

  return (
    <div className="app-retro__menu">
      <div className="container">

        <h1>The Legend of Folio</h1>

        <button type="button" onClick={handleNewGame}>new Game</button>
        <button type="button" onClick={handleSettingsGame}>Settings</button>
        <button type="button" onClick={handleExitGame}>Exit</button>
      </div>
    </div>
  );
}

import Phaser from 'phaser';
import DropItem from './DropItem';

export default class MatterEntity extends Phaser.Physics.Matter.Sprite {
  #position;

  constructor({
    scene, x, y, name, health, drops, texture, frame, depth,
  }) {
    super(scene.matter.world, x, y, texture, frame);

    this.depth = depth || 1;
    this.name = name;
    this.health = health;
    this.drops = drops;

    // Position
    this.x += this.width / 2;
    this.y -= this.height / 2;
    this.#position = new Phaser.Math.Vector2(this.x, this.y);

    // Object sound
    if (this.name) this.sound = scene.sound.add(this.name);

    // Add to scene
    this.scene.add.existing(this);
  }

  get position() {
    this.#position.set(this.x, this.y);
    return this.#position;
  }

  get velocity() {
    return this.body.velocity;
  }

  get dead() {
    return this.health <= 0;
  }

  hit = () => {
    console.log('hit');

    if (this.sound) {
      this.sound.play();
    }

    this.health -= 1;

    if (this.dead) {
      this.onDeath();
      this.drops.forEach((drop) => new DropItem({
        scene: this.scene, frame: drop, x: this.x, y: this.y,
      }));
    }
  };
}

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { close as closeWindow, upgradeWindowPosition } from '../../../reducers/windows';

import WINDOWS_TYPE from './Type';

import Finder from '../Applications/Finder/Finder';
import GuideWelcome from '../Applications/Guide/Welcome/Welcome';
import Avis from '../Applications/Avis/Avis';

import './style.scss';
import Mail from '../Applications/Mail/Mail';
import Settings from '../Applications/Settings/Settings';
import About from '../Applications/About/About';
import { setFirstStart } from '../../../reducers/ubuntu';
import Screensaver from '../Screensaver/Screensaver';

/**
 * Window component
 *
 * @param onStart
 * @param type
 * @param xPosition
 * @param yPosition
 * @return {JSX.Element}
 * @constructor
 */
export default function Window({
  windowID,
  type,
  xPosition,
  yPosition,
  zIndex,
  onStartMoveWindow,
  // position,
  // setWindowMaximise,
  // setWindowOnTop,
}) {
  const dispatch = useDispatch();

  const [isMaximised, setIsMaximised] = useState(false);

  const onClose = () => {
    dispatch(closeWindow({ windowID }));

    if (type === WINDOWS_TYPE.GUIDE_WELCOME) {
      dispatch(setFirstStart(false));
    }
  };

  const onClickMaximise = () => {
    if (isMaximised) {
      setIsMaximised(false);
      // setWindowMaximise(false);
    } else {
      setIsMaximised(true);
      // setWindowMaximise(true);
    }
  };

  return (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`window dragger-container ${isMaximised ? 'maximised' : ''} ${type === WINDOWS_TYPE.GUIDE_WELCOME ? 'guide-welcome' : ''}`}
      style={{
        left: `calc(50% - 300px + ${xPosition}px)`,
        top: `calc(50% - 400px + ${yPosition}px)`,
        zIndex,
      }}
      onClick={() => dispatch(upgradeWindowPosition(windowID))}
    >
      <div
        role="presentation"
        className="window-menu  dragger-element"
        onMouseDown={(event) => {
          onStartMoveWindow(event);
          dispatch(upgradeWindowPosition(windowID));
        }}
        onTouchStart={(event) => {
          onStartMoveWindow(event);
          dispatch(upgradeWindowPosition(windowID));
        }}
      >
        <div className="window-menu__controls">
          <button
            type="button"
            className="window-menu__controls__item btn-close"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </button>
          <button type="button" className="window-menu__controls__item btn-minus">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
              />
            </svg>
          </button>
          <button
            type="button"
            className="window-menu__controls__item btn-fullscreen"
            onClick={onClickMaximise}
          >
            {!isMaximised
              ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
                  />
                </svg>
              )
              : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M24 432c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24z"
                  />
                </svg>
              )}
          </button>
        </div>
        <div className="window-menu__title">
          <span>
            ID:
            {windowID}
          </span>
        </div>
      </div>
      <div className="window-container">
        <div className="window-content">

          {type === WINDOWS_TYPE.DASHBOARD && (<Finder windowID={windowID} />)}
          {type === WINDOWS_TYPE.FINDER && (<Finder windowID={windowID} />)}
          {type === WINDOWS_TYPE.AVIS && (<Avis windowID={windowID} />)}
          {type === WINDOWS_TYPE.WHOAMI && (<Finder windowID={windowID} />)}
          {type === WINDOWS_TYPE.SKILLS && (<Finder windowID={windowID} />)}
          {type === WINDOWS_TYPE.PORTFOLIO && (<Finder windowID={windowID} />)}
          {type === WINDOWS_TYPE.MAILS && (<Mail windowID={windowID} />)}
          {type === WINDOWS_TYPE.SETTINGS && (<Settings windowID={windowID} />)}
          {type === WINDOWS_TYPE.TRASH && (<Finder windowID={windowID} />)}
          {type === WINDOWS_TYPE.GUIDE_WELCOME && (<GuideWelcome windowID={windowID} />)}
          {type === WINDOWS_TYPE.ABOUT && (<About windowID={windowID} />)}
          {type === WINDOWS_TYPE.SCREENSAVER && (<Screensaver windowID={windowID} />)}
        </div>
      </div>
    </div>
  );
}

Window.propTypes = {
  windowID: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  xPosition: PropTypes.number.isRequired,
  yPosition: PropTypes.number.isRequired,
  zIndex: PropTypes.number.isRequired,
  onStartMoveWindow: PropTypes.func.isRequired,
};

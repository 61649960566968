import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import packageJson from '../../../package.json';

import './style.scss';
import { PAGE_VIEW, setCurrentPage, setIsLoading } from '../../reducers/app';

export default function App() {
  const dispatch = useDispatch();

  // OS Versions
  const [version] = useState(`${packageJson.version}`);

  const ref = React.useRef(null);

  const [date] = useState(new Date());

  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [text4, setText4] = useState('');
  const [text5, setText5] = useState('');
  const [text6, setText6] = useState('');
  const [text7, setText7] = useState('');
  const [text8, setText8] = useState('');
  const [text9, setText9] = useState('');
  const [text10, setText10] = useState('');

  const [memoryTestStart, setMemoryTestStart] = useState(false);
  const [memoryTest, setMemoryTest] = useState(false);

  const [test1Start, setTest1Start] = useState(false);
  const [test1Finish, setTest1Finish] = useState(false);

  const [test2Start, setTest2Start] = useState(false);
  const [test2Finish, setTest2Finish] = useState(false);

  const [test3Start, setTest3Start] = useState(false);
  const [test3Finish, setTest3Finish] = useState(false);

  const [exit, setExit] = useState(false);

  // const txt = 'Porfolio BIOS v1.0';

  useEffect(() => {
    const speed = 1;

    let i1 = 0;
    let i2 = 0;
    let i3 = 0;
    let i4 = 0;
    let i5 = 0;
    let i6 = 0;
    let i7 = 0;
    let i8 = 0;
    let i9 = 0;
    let i10 = 0;

    const typeWriter10 = (txt, setter) => {
      if (i10 < txt.length) {
        setter(txt.substring(0, i10 += 1));
        setTimeout(() => typeWriter10(txt, setter), speed);
      } else {
        setTest3Start(true);
        setTimeout(() => {
          setTest3Finish(true);

          setTimeout(() => {
            setExit(true);

            setTimeout(() => {
              dispatch(setCurrentPage(PAGE_VIEW.GRUB));
              dispatch(setIsLoading(false));
            }, 250);
          }, 250);
        }, 400);
      }
    };

    const typeWriter9 = (txt, setter) => {
      if (i9 < txt.length) {
        setter(txt.substring(0, i9 += 1));
        setTimeout(() => typeWriter9(txt, setter), speed);
      } else {
        setTest2Start(true);
        setTimeout(() => {
          setTest2Finish(true);
          setTimeout(() => typeWriter10('Detecting IDE Primary Master ... ', setText10), 25);
        }, 400);
      }
    };

    const typeWriter8 = (txt, setter) => {
      if (i8 < txt.length) {
        setter(txt.substring(0, i8 += 1));
        setTimeout(() => typeWriter8(txt, setter), speed);
      } else {
        setTimeout(() => {
          setTest1Finish(true);
          setTimeout(() => typeWriter9('Detecting IDE Primary Master ... ', setText9), 25);
        }, 400);
      }
    };

    const typeWriter7 = (txt, setter) => {
      if (i7 < txt.length) {
        setter(txt.substring(0, i7 += 1));
        setTimeout(() => typeWriter7(txt, setter), speed);
      } else {
        setTest1Start(true);
        setTimeout(() => typeWriter8('Detecting IDE Primary Master ... ', setText8), 25);
      }
    };

    const typeWriter6 = (txt, setter) => {
      if (i6 < txt.length) {
        setter(txt.substring(0, i6 += 1));
        setTimeout(() => typeWriter6(txt, setter), speed);
      } else {
        setTimeout(() => typeWriter7('Copyright (C) 2023, William Malbos', setText7), 25);
      }
    };

    const typeWriter5 = (txt, setter) => {
      if (i5 < txt.length) {
        setter(txt.substring(0, i5 += 1));
        setTimeout(() => typeWriter5(txt, setter), speed);
      } else {
        const target = 8064;
        let number = 0;
        setMemoryTestStart(true);
        setTimeout(() => {
          const interval = setInterval(() => {
            ref.current.textContent = number;
            if (number >= target) {
              clearInterval(interval);
              setMemoryTest(true);
              setTimeout(() => typeWriter6('Award Plug and Plug BIOS Extension v1.0A', setText6), 150);
            }
            number += 203;
          }, 30);
        }, 100);
      }
    };

    const typeWriter4 = (txt, setter) => {
      if (i4 < txt.length) {
        setter(txt.substring(0, i4 += 1));
        setTimeout(() => typeWriter4(txt, setter), speed);
      } else {
        setTimeout(() => typeWriter5('Memory Test : ', setText5), 25);
      }
    };

    const typeWriter3 = (txt, setter) => {
      if (i3 < txt.length) {
        setter(txt.substring(0, i3 += 1));
        setTimeout(() => typeWriter3(txt, setter), speed);
      } else {
        setTimeout(() => typeWriter4('WEBINIUM ODP-MMX CPU at 200MHz', setText4), 25);
      }
    };

    const typeWriter2 = (txt, setter) => {
      if (i2 < txt.length) {
        setter(txt.substring(0, i2 += 1));
        setTimeout(() => typeWriter2(txt, setter), speed);
      } else {
        setTimeout(() => typeWriter3('Version JE4329', setText3), 25);
      }
    };

    const typeWriter1 = (txt, setter) => {
      if (i1 < txt.length) {
        setter(txt.substring(0, i1 += 1));
        setTimeout(() => typeWriter1(txt, setter), speed);
      } else {
        setTimeout(() => typeWriter2(`Copyright (C) ${date.getFullYear()}, William Malbos - https://www.wmalbos.fr`, setText2), 25);
      }
    };

    setTimeout(() => typeWriter1(`Web OS Portfolio v${version}`, setText1), 0);
  }, []);

  return (
    <>
      {exit && (<div className="app-loading--exit" />)}
      {!exit && (
      <div className="app-loading">

        <div className="noise" />
        <div className="overlay" />
        <div className="term" />

        <div className="container">
          <p className="line line-break">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_&nbsp;&nbsp;
            _&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            |&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;
            <br />
            __&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;____&nbsp;&nbsp;&nbsp;__&nbsp;&nbsp;&nbsp;___&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;__&nbsp;&nbsp;&nbsp;_&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            |&nbsp;&nbsp;&nbsp;|&nbsp;
            __&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ___&nbsp;
            <br />
            \&nbsp;&nbsp;&nbsp;\&nbsp;&nbsp;&nbsp;/&nbsp;\&nbsp;&nbsp;&nbsp;/&nbsp;
            &nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&apos;_&nbsp;&nbsp;&nbsp;&nbsp;`
            &nbsp;_&nbsp;&nbsp;&nbsp;&nbsp;\&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;_`&nbsp;
            &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            |&nbsp;&nbsp;&nbsp;&nbsp;&apos;_&nbsp;
            &nbsp;&nbsp;\&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;_&nbsp;&nbsp;&nbsp;\&nbsp;/&nbsp;&nbsp;
            __&nbsp;|
            <br />
            &nbsp;&nbsp;\&nbsp;&nbsp;&nbsp;V&nbsp;&nbsp;&nbsp;&nbsp;V&nbsp;&nbsp;&nbsp;
            /|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            |&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            (&nbsp;_&nbsp;|&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            |&nbsp;&nbsp;&nbsp;|&nbsp;_&nbsp;)&nbsp;&nbsp;
            |&nbsp;&nbsp;&nbsp;(&nbsp;_&nbsp;)&nbsp;&nbsp;&nbsp;\&nbsp;__&nbsp;&nbsp;
            &nbsp;\&nbsp;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;\&nbsp;_&nbsp;/&nbsp;\&nbsp;_&nbsp;/&nbsp;
            |&nbsp;_&nbsp;|&nbsp;&nbsp;&nbsp;|&nbsp;_&nbsp;|&nbsp;&nbsp;&nbsp;|&nbsp;_
            |\&nbsp;__&nbsp;,&nbsp;_&nbsp;|&nbsp;_&nbsp;|&nbsp;_&nbsp;.&nbsp;__
            /&nbsp;\&nbsp;___&nbsp;/&nbsp;&nbsp;|&nbsp;___&nbsp;/
          </p>

          <p className="first-line line">{text1}</p>
          <p className="line line-break">{text2}</p>

          <p className="line line-break">{text3}</p>
          <p className="line line-break">{text4}</p>

          <p className="line line-break">
            {text5}
            <span className={`memory-test ${memoryTestStart ? 'active' : ''}`}>
              <span ref={ref}>5</span>
              <span> / 8109</span>
              {` KB  ... ${memoryTest ? 'OK' : ''}`}
            </span>
          </p>

          <p className="line ">{text6}</p>
          <p className="line ">{text7}</p>

          <p className="line line-space">
            {text8}
            <span className={`memory-test ${test1Start ? 'active' : ''}`}>
              {` ${test1Finish ? 'OK' : 'In progress'}`}
            </span>
          </p>

          <p className="line line-space">
            {text9}
            <span className={`memory-test ${test2Start ? 'active' : ''}`}>
              {` ${test2Finish ? 'OK' : 'In progress'}`}
            </span>
          </p>

          <p className="line line-space line-break">
            {text10}
            <span className={`memory-test ${test3Start ? 'active' : ''}`}>
              {` ${test3Finish ? 'OK' : 'In progress'}`}
            </span>
          </p>
        </div>
      </div>
      )}
    </>
  );
}

import React from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import { create as newWindow } from '../../../reducers/windows';

/**
 * Button component
 *
 * @param icon
 * @param title
 * @param windowType
 * @return {JSX.Element}
 * @constructor
 */
export default function Button({
  title,
  windowType,
  icon,
}) {
  const dispatch = useDispatch();

  const handleClickButton = () => {
    dispatch(newWindow({
      type: windowType,
    }));
  };

  return (
    <button type="button" className="button" onClick={handleClickButton}>
      <div className="button__image">
        <img src={icon} alt={title} className="button__icon" />
      </div>
      <span className="button__arrow-shadow" />
      <span className="button__arrow" />
      <span className="button__label">{title}</span>
    </button>
  );
}

Button.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  windowType: PropTypes.string.isRequired,
};

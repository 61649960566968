import React from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import WINDOWS_TYPE from '../../../window/Type';
import { create as newWindow, close as closeWindow } from '../../../../../reducers/windows';

import ICON_FILES from './icon-files.png';
import ICON_WHOAMI from './icon-whoami.png';
import ICON_MAILS from './icon-mails.png';
import ICON_APPS from './icon-apps.png';
import ICON_SKILLS from './icon-skills.png';
import ICON_MALT from './icon-malt.png';
import ICON_LINKEDIN from './icon-linkedin.png';
import ICON_OPENCLASSROOMS from './icon-openclassrooms.png';

import './style.scss';
import { setFirstStart } from '../../../../../reducers/ubuntu';

export default function Welcome({
  windowID,
}) {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeWindow({
      windowID,
    }));
    dispatch(setFirstStart(false));
  };

  const onClickButton = () => {
    dispatch(newWindow({
      type: WINDOWS_TYPE.FINDER,
    }));

    onClose();
  };

  return (
    <div className="welcome">
      <div />
      <div>
        <h1>Bienvenue dans la visite guidée du portfolio de William Malbos</h1>
        <p>Développeur web freelance</p>
      </div>
      <div className="welcome__content">

        <button type="button" className="content__item" onClick={() => onClickButton('FINDER')}>
          <div className="content__item__image">
            <img src={ICON_OPENCLASSROOMS} alt="" />
          </div>
          <span className="content__item__label">Mentorat OpenClassrooms</span>
        </button>

        <button type="button" className="content__item">
          <div className="content__item__image">
            <img src={ICON_APPS} alt="" />
          </div>
          <span className="content__item__label">Mon portfolio</span>
        </button>
        <button type="button" className="content__item">
          <div className="content__item__image">
            <img src={ICON_MAILS} alt="" />
          </div>
          <span className="content__item__label">Me contacter</span>
        </button>

        <button type="button" className="content__item">
          <div className="content__item__image">
            <img src={ICON_WHOAMI} alt="" />
          </div>
          <span className="content__item__label">Qui suis-je ?</span>
        </button>

        <button type="button" className="content__item">
          <div className="content__item__image">
            <img src={ICON_SKILLS} alt="" />
          </div>
          <span className="content__item__label">Mes compétences</span>
        </button>

        <button type="button" className="content__item" onClick={() => onClickButton('FINDER')}>
          <div className="content__item__image">
            <img src={ICON_FILES} alt="" />
          </div>
          <span className="content__item__label">Parcourir les dossiers</span>
        </button>

      </div>

      <div className="welcome__networks">
        <a href="https://www.malt.fr/profile/wmalbos" target="_blank" rel="noopener nofollow noreferrer" className="content__item">
          <div className="content__item__image">
            <img src={ICON_MALT} alt="" />
          </div>
          <span className="content__item__label">Malt</span>
        </a>

        <a href="https://www.malt.fr/profile/wmalbos" target="_blank" rel="noopener nofollow noreferrer" className="content__item">
          <div className="content__item__image">
            <img src={ICON_LINKEDIN} alt="" />
          </div>
          <span className="content__item__label">LinkedIn</span>
        </a>

      </div>

      <div className="welcome__controls">
        <div>
          <button type="button" className="btn-french active">Français</button>
          <button type="button" className="btn-english">Anglais</button>
        </div>
        <div>
          <button type="button" className="btn-follow">Suivre le guide</button>
          <button
            type="button"
            className="btn-no active"
            onClick={onClose}
          >
            Découvrez par vous-même
          </button>
        </div>
      </div>
    </div>
  );
}

Welcome.propTypes = {
  windowID: PropTypes.number.isRequired,
};

import React from 'react';

import Button from './Button';

import WINDOWS_TYPE from '../window/Type';

import ICON_DASHBOARD from './icon-dash.png';
import ICON_FOLDER from './icon-folder.png';
import ICON_WHOAMI from './icon-whoami.png';
import ICON_SKILLS from './icon-skills.png';
import ICON_PORTFOLIO from './icon-apps.png';
import ICON_CONTACT from './icon-mails.png';
import ICON_SETTINGS from './icon-settings.png';
import ICON_TRASH from './icon-trash.png';
import ICON_TERMINAL from './icon-terminal.png';
import ICON_GUIDE from './icon-guide.png';
import ICON_OPENCLASSROOMS from './icon-openclassrooms.png';
import ICON_AVIS from './icon-avis.png';

import './style.scss';

/**
 * Sidebar
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function Sidebar() {
  return (
    <div className="screen-sidebar">
      <div className="screen-sidebar__top">
        <Button title="Tableau de bord" windowType={WINDOWS_TYPE.FINDER} icon={ICON_DASHBOARD} />
        <Button title="Dossiers" windowType={WINDOWS_TYPE.FINDER} icon={ICON_FOLDER} />
        <Button title="Qui suis-je ?" windowType={WINDOWS_TYPE.FINDER} icon={ICON_WHOAMI} />
        <Button title="Compétences" windowType={WINDOWS_TYPE.FINDER} icon={ICON_SKILLS} />
        <Button title="Portfolio" windowType={WINDOWS_TYPE.FINDER} icon={ICON_PORTFOLIO} />
        <Button title="Mentorat OpenClassrooms" windowType={WINDOWS_TYPE.FINDER} icon={ICON_OPENCLASSROOMS} />
        <Button title="Me contacter" windowType={WINDOWS_TYPE.MAILS} icon={ICON_CONTACT} />
        <Button title="Recommandations" windowType={WINDOWS_TYPE.FINDER} icon={ICON_AVIS} />
        <Button title="Terminal" windowType={WINDOWS_TYPE.FINDER} icon={ICON_TERMINAL} />
        <Button title="Paramètres" windowType={WINDOWS_TYPE.AVIS} icon={ICON_SETTINGS} />
      </div>
      <div className="screen-sidebar__bottom">
        <Button title="Visite guidée" windowType={WINDOWS_TYPE.GUIDE_WELCOME} icon={ICON_GUIDE} />
        <Button title="Corbeille" windowType={WINDOWS_TYPE.FINDER} icon={ICON_TRASH} />
      </div>
    </div>
  );
}

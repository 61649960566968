import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Dragger from '../components/Ubuntu/Dragger/Dragger';
import BaseLoader from '../components/Loader/Loader';
import Grub from '../components/Grub/Grub';

import UbuntuLoader from '../components/Ubuntu/Loader/Loader';
import Guide from '../components/Ubuntu/Applications/Guide/Guide';
import Login from '../components/Ubuntu/Login/Login';
import Window from '../components/Ubuntu/window/Window';
import Background from '../components/Ubuntu/Background/Background';
import Sidebar from '../components/Ubuntu/Sidebar/Sidebar';
import Topbar from '../components/Ubuntu/Topbar/Topbar';

import RetroLoader from '../components/Retro/Loader/Loader';
import RetroMenu from '../components/Retro/Menu/Menu';
import RetroGame from '../components/Retro/Game/Game';

import RoomLoader from '../components/Room/Loader/Loader';
import RoomMenu from '../components/Room/Menu/Menu';
import RoomGame from '../components/Room/Game/Game';

import {
  PAGE_VIEW, setCurrentPage, setLanguage, setSoundVolume,
} from '../reducers/app';

import '../style.scss';
import { loginByToken } from '../reducers/user';
import { setFirstStart, setMailRead, setMails } from '../reducers/ubuntu';
import Screensaver from '../components/Ubuntu/Screensaver/Screensaver';

function Router() {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const windowsList = useSelector((state) => state.windows.list);
  const ubuntu = useSelector((state) => state.ubuntu);

  const [settingsExist, setSettingsExist] = useState(null);

  useEffect(() => {
    let settingsFound = false;

    const currentPage = localStorage.getItem('currentPage');
    if (currentPage) {
      dispatch(setCurrentPage(currentPage));
      settingsFound = true;
    }

    const language = localStorage.getItem('language');
    if (language) {
      dispatch(setLanguage(language));
    }

    const soundVolume = localStorage.getItem('soundVolume');
    if (soundVolume) {
      dispatch(setSoundVolume(soundVolume));
    }

    const user = localStorage.getItem('user');
    if (user) {
      dispatch(loginByToken(JSON.parse(user)));
    }

    dispatch(setMails([
      {
        id: 1,
        title: 'Bienvenue !',
        from: 'William M.',
        date: '04/05/2023',
        content: 'lorem ipsum dolor set imae',
        read: false,
      },
      {
        id: 2,
        title: 'Comment utiliser votre boite mail ?',
        from: 'William M.',
        date: '04/05/2023',
        content: 'lorem ipsum dolor set imae',
        read: false,
      },
    ]));
    const mailsRead = localStorage.getItem('mailsRead');
    if (mailsRead) {
      JSON.parse(mailsRead).forEach((mailID) => {
        dispatch(setMailRead(mailID));
      });
    }

    const firstUbuntuStart = localStorage.getItem('firstUbuntuStart');
    if (firstUbuntuStart) {
      dispatch(setFirstStart(JSON.parse(firstUbuntuStart)));
    }

    setSettingsExist(settingsFound);
  }, []);

  if (settingsExist === null) {
    return (<div />);
  }

  if (!settingsExist && app.isLoading) {
    return <BaseLoader />;
  }

  return (
    <div>
      <Dragger>
        {(onStartMoveWindow) => (
          <>
            {app.currentPage === PAGE_VIEW.GRUB && (<Grub />)}

            {app.currentPage === PAGE_VIEW.UBUNTU_SCREENSAVER && (<Screensaver />)}
            {app.currentPage === PAGE_VIEW.UBUNTU_LOADER && (<UbuntuLoader />)}
            {app.currentPage === PAGE_VIEW.UBUNTU_LOGIN && (<Login />)}
            {app.currentPage === PAGE_VIEW.UBUNTU && (
              <>
                <Background />
                <Sidebar />
                <Topbar />

                {ubuntu.firstStart && (<Guide />)}
                {windowsList.length !== 0 && (
                <>
                  {windowsList.map((item) => (
                    <Window
                      key={item.windowID}
                      windowID={item.windowID}
                      type={item.type}
                      xPosition={item.xPosition}
                      yPosition={item.yPosition}
                      zIndex={item.zIndex}
                      onStartMoveWindow={onStartMoveWindow}
                    />
                  ))}
                </>
                )}
              </>
            )}

            {app.currentPage === PAGE_VIEW.RETRO_LOADER && (<RetroLoader />)}
            {app.currentPage === PAGE_VIEW.RETRO_MENU && (<RetroMenu />)}
            {app.currentPage === PAGE_VIEW.RETRO_GAME && (<RetroGame />)}

            {app.currentPage === PAGE_VIEW.ROOM_LOADER && (<RoomLoader />)}
            {app.currentPage === PAGE_VIEW.ROOM_MENU && (<RoomMenu />)}
            {app.currentPage === PAGE_VIEW.ROOM_GAME && (<RoomGame />)}
          </>
        )}

      </Dragger>
    </div>
  );
}

export default Router;

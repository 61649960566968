const WINDOWS_TYPE = {
  DASHBOARD: 'DASHBOARD',
  FINDER: 'FINDER',
  WHOAMI: 'WHOAMI',
  SKILLS: 'SKILLS',
  PORTFOLIO: 'PORTFOLIO',
  MAILS: 'MAILS',
  SETTINGS: 'SETTINGS',
  TRASH: 'TRASH',
  GUIDE_WELCOME: 'GUIDE_WELCOME',
  AVIS: 'AVIS',
  ABOUT: 'ABOUT',
  SCREENSAVER: 'SCREENSAVER',
};

export default WINDOWS_TYPE;
